define("discourse/plugins/chat/discourse/components/chat/selection-manager", ["exports", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/delete-messages-confirm", "discourse/plugins/chat/discourse/components/chat/modal/move-message-to-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _application, _object, _service, _truthHelpers, _dButton, _ajaxError, _utilities, _environment, _decorators, _discourseI18n, _deleteMessagesConfirm, _moveMessageToChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _ChatSelectionManager;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const DELETE_COUNT_LIMIT = 50;
  let ChatSelectionManager = _exports.default = (_dec = (0, _service.service)("composer"), _dec2 = (0, _service.service)("chat-api"), (_class = (_ChatSelectionManager = class ChatSelectionManager extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "topicComposer", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "modal", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
      _initializerDefineProperty(this, "toasts", _descriptor5, this);
      _initializerDefineProperty(this, "currentUser", _descriptor6, this);
      _initializerDefineProperty(this, "api", _descriptor7, this);
    }
    get enableMove() {
      return this.args.enableMove ?? false;
    }
    get anyMessagesSelected() {
      return this.args.pane.selectedMessageIds.length > 0;
    }
    get deleteCountLimitReached() {
      return this.args.pane.selectedMessageIds.length > DELETE_COUNT_LIMIT;
    }
    get canDeleteMessages() {
      return this.args.pane.selectedMessageIds.every(id1 => {
        return this.canDeleteMessage(id1);
      });
    }
    canDeleteMessage(id1) {
      const message1 = this.args.messagesManager?.findMessage(id1);
      if (message1) {
        const canDelete1 = this.currentUser.id === message1.user.id ? message1.channel?.canDeleteSelf : message1.channel?.canDeleteOthers;
        return canDelete1 && !message1.deletedAt && message1.channel?.canModifyMessages?.(this.currentUser);
      }
    }
    get deleteButtonTitle() {
      return _discourseI18n.default.t("chat.selection.delete", {
        selectionCount: this.args.pane.selectedMessageIds.length,
        totalCount: DELETE_COUNT_LIMIT
      });
    }
    async generateQuote() {
      const {
        markdown: markdown1
      } = await this.api.generateQuote(this.args.pane.channel.id, this.args.pane.selectedMessageIds);
      return new Blob([markdown1], {
        type: "text/plain"
      });
    }
    openMoveMessageModal() {
      this.modal.show(_moveMessageToChannel.default, {
        model: {
          sourceChannel: this.args.pane.channel,
          selectedMessageIds: this.args.pane.selectedMessageIds
        }
      });
    }
    openDeleteMessagesModal() {
      this.modal.show(_deleteMessagesConfirm.default, {
        model: {
          sourceChannel: this.args.pane.channel,
          selectedMessageIds: this.args.pane.selectedMessageIds
        }
      });
    }
    async quoteMessages() {
      let quoteMarkdown1;
      try {
        const quoteMarkdownBlob1 = await this.generateQuote();
        quoteMarkdown1 = await quoteMarkdownBlob1.text();
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
      const openOpts1 = {};
      if (this.args.pane.channel.isCategoryChannel) {
        openOpts1.categoryId = this.args.pane.channel.chatableId;
      }
      if (this.site.mobileView) {
        // go to the relevant chatable (e.g. category) and open the
        // composer to insert text
        if (this.args.pane.channel.chatableUrl) {
          this.router.transitionTo(this.args.pane.channel.chatableUrl);
        }
        await this.topicComposer.focusComposer({
          fallbackToNewTopic: true,
          insertText: quoteMarkdown1,
          openOpts: openOpts1
        });
      } else {
        // open the composer and insert text, reply to the current
        // topic if there is one, use the active draft if there is one
        const container1 = (0, _application.getOwner)(this);
        const topic1 = container1.lookup("controller:topic");
        await this.topicComposer.focusComposer({
          fallbackToNewTopic: true,
          topic: topic1?.model,
          insertText: quoteMarkdown1,
          openOpts: openOpts1
        });
      }
    }
    async copyMessages() {
      try {
        if (!(0, _environment.isTesting)()) {
          // clipboard API throws errors in tests
          await (0, _utilities.clipboardCopyAsync)(this.generateQuote);
          this.toasts.success({
            duration: 3000,
            data: {
              message: _discourseI18n.default.t("chat.quote.copy_success")
            }
          });
        }
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class="chat-selection-management"
        data-last-copy-successful={{this.lastCopySuccessful}}
      >
        <div class="chat-selection-management__buttons">
          <DButton
            @icon="quote-left"
            @label="chat.selection.quote_selection"
            @disabled={{not this.anyMessagesSelected}}
            @action={{this.quoteMessages}}
            id="chat-quote-btn"
          />
  
          <DButton
            @icon="copy"
            @label="chat.selection.copy"
            @disabled={{not this.anyMessagesSelected}}
            @action={{this.copyMessages}}
            id="chat-copy-btn"
          />
  
          {{#if this.enableMove}}
            <DButton
              @icon="sign-out-alt"
              @label="chat.selection.move_selection_to_channel"
              @disabled={{not this.anyMessagesSelected}}
              @action={{this.openMoveMessageModal}}
              id="chat-move-to-channel-btn"
            />
          {{/if}}
  
          <DButton
            @icon="trash-alt"
            @translatedLabel={{this.deleteButtonTitle}}
            @disabled={{or
              (not this.anyMessagesSelected)
              (not this.canDeleteMessages)
              this.deleteCountLimitReached
            }}
            @action={{this.openDeleteMessagesModal}}
            id="chat-delete-btn"
          />
  
          <DButton
            @icon="times"
            @label="chat.selection.cancel"
            @action={{@pane.cancelSelecting}}
            id="chat-cancel-selection-btn"
            class="btn-secondary cancel-btn"
          />
        </div>
      </div>
    
  */
  {
    "id": "/Gifzobp",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-selection-management\"],[15,\"data-last-copy-successful\",[30,0,[\"lastCopySuccessful\"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-selection-management__buttons\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,1,\"chat-quote-btn\"]],[[\"@icon\",\"@label\",\"@disabled\",\"@action\"],[\"quote-left\",\"chat.selection.quote_selection\",[28,[32,1],[[30,0,[\"anyMessagesSelected\"]]],null],[30,0,[\"quoteMessages\"]]]],null],[1,\"\\n\\n        \"],[8,[32,0],[[24,1,\"chat-copy-btn\"]],[[\"@icon\",\"@label\",\"@disabled\",\"@action\"],[\"copy\",\"chat.selection.copy\",[28,[32,1],[[30,0,[\"anyMessagesSelected\"]]],null],[30,0,[\"copyMessages\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"enableMove\"]],[[[1,\"          \"],[8,[32,0],[[24,1,\"chat-move-to-channel-btn\"]],[[\"@icon\",\"@label\",\"@disabled\",\"@action\"],[\"sign-out-alt\",\"chat.selection.move_selection_to_channel\",[28,[32,1],[[30,0,[\"anyMessagesSelected\"]]],null],[30,0,[\"openMoveMessageModal\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[32,0],[[24,1,\"chat-delete-btn\"]],[[\"@icon\",\"@translatedLabel\",\"@disabled\",\"@action\"],[\"trash-alt\",[30,0,[\"deleteButtonTitle\"]],[28,[32,2],[[28,[32,1],[[30,0,[\"anyMessagesSelected\"]]],null],[28,[32,1],[[30,0,[\"canDeleteMessages\"]]],null],[30,0,[\"deleteCountLimitReached\"]]],null],[30,0,[\"openDeleteMessagesModal\"]]]],null],[1,\"\\n\\n        \"],[8,[32,0],[[24,1,\"chat-cancel-selection-btn\"],[24,0,\"btn-secondary cancel-btn\"]],[[\"@icon\",\"@label\",\"@action\"],[\"times\",\"chat.selection.cancel\",[30,1,[\"cancelSelecting\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@pane\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/selection-manager.js",
    "scope": () => [_dButton.default, _truthHelpers.not, _truthHelpers.or],
    "isStrictMode": true
  }), _ChatSelectionManager), _ChatSelectionManager), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "topicComposer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toasts", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "generateQuote", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "generateQuote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openMoveMessageModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openMoveMessageModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openDeleteMessagesModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDeleteMessagesModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "quoteMessages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "quoteMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyMessages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyMessages"), _class.prototype)), _class));
});