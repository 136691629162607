define("discourse/plugins/chat/discourse/components/chat/navbar/thread-tracking-dropdown", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse/lib/notification-levels", "discourse/plugins/chat/discourse/components/chat-thread-tracking-dropdown", "discourse/plugins/chat/discourse/models/user-chat-thread-membership", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _concatClass, _ajaxError, _notificationLevels, _chatThreadTrackingDropdown, _userChatThreadMembership, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatNavbarThreadTrackingDropdown;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatNavbarThreadTrackingDropdown = _exports.default = (_class = (_ChatNavbarThreadTrackingDropdown = class ChatNavbarThreadTrackingDropdown extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "persistedNotificationLevel", _descriptor2, this);
    }
    get threadNotificationLevel() {
      return this.membership?.notificationLevel || _notificationLevels.NotificationLevels.REGULAR;
    }
    get membership() {
      return this.args.thread.currentUserMembership;
    }
    async updateThreadNotificationLevel(newNotificationLevel1) {
      this.persistedNotificationLevel = false;
      let currentNotificationLevel1;
      if (this.membership) {
        currentNotificationLevel1 = this.membership.notificationLevel;
        this.membership.notificationLevel = newNotificationLevel1;
      } else {
        this.args.thread.currentUserMembership = _userChatThreadMembership.default.create({
          notification_level: newNotificationLevel1,
          last_read_message_id: null
        });
      }
      try {
        const response1 = await this.chatApi.updateCurrentUserThreadNotificationsSettings(this.args.thread.channel.id, this.args.thread.id, {
          notificationLevel: newNotificationLevel1
        });
        this.membership.last_read_message_id = response1.membership.last_read_message_id;
        this.persistedNotificationLevel = true;
      } catch (error1) {
        this.membership.notificationLevel = currentNotificationLevel1;
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ThreadTrackingDropdown
        @value={{this.threadNotificationLevel}}
        @onChange={{this.updateThreadNotificationLevel}}
        class={{concatClass
          "c-navbar__thread-tracking-dropdown"
          (if this.persistedNotificationLevel "-persisted")
        }}
      />
    
  */
  {
    "id": "saHWpeA3",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"c-navbar__thread-tracking-dropdown\",[52,[30,0,[\"persistedNotificationLevel\"]],\"-persisted\"]],null]]],[[\"@value\",\"@onChange\"],[[30,0,[\"threadNotificationLevel\"]],[30,0,[\"updateThreadNotificationLevel\"]]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/thread-tracking-dropdown.js",
    "scope": () => [_chatThreadTrackingDropdown.default, _concatClass.default],
    "isStrictMode": true
  }), _ChatNavbarThreadTrackingDropdown), _ChatNavbarThreadTrackingDropdown), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "persistedNotificationLevel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateThreadNotificationLevel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateThreadNotificationLevel"), _class.prototype)), _class);
});