define("discourse/plugins/chat/discourse/components/chat/admin/export-messages", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajaxError, _i18n, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatAdminExportMessages;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatAdminExportMessages = _exports.default = (_class = (_ChatAdminExportMessages = class ChatAdminExportMessages extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatAdminApi", _descriptor, this);
      _initializerDefineProperty(this, "dialog", _descriptor2, this);
    }
    async exportMessages() {
      try {
        await this.chatAdminApi.exportMessages();
        this.dialog.alert(_discourseI18n.default.t("chat.admin.export_messages.export_has_started"));
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="admin-section">
        <h3>{{i18n "chat.admin.export_messages.title"}}</h3>
        <p>{{i18n "chat.admin.export_messages.description"}}</p>
        <DButton
          @label="chat.admin.export_messages.create_export"
          @title="chat.admin.export_messages.create_export"
          @action={{this.exportMessages}}
          class="btn-primary"
        />
      </section>
    
  */
  {
    "id": "wNhgVu+r",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"admin-section\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"chat.admin.export_messages.title\"],null]],[13],[1,\"\\n      \"],[10,2],[12],[1,[28,[32,0],[\"chat.admin.export_messages.description\"],null]],[13],[1,\"\\n      \"],[8,[32,1],[[24,0,\"btn-primary\"]],[[\"@label\",\"@title\",\"@action\"],[\"chat.admin.export_messages.create_export\",\"chat.admin.export_messages.create_export\",[30,0,[\"exportMessages\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/admin/export-messages.js",
    "scope": () => [_i18n.default, _dButton.default],
    "isStrictMode": true
  }), _ChatAdminExportMessages), _ChatAdminExportMessages), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatAdminApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "exportMessages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "exportMessages"), _class.prototype)), _class);
});