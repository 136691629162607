define("discourse/plugins/chat/discourse/components/chat-channel-archive-status", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _template2, _utils, _dButton, _concatClass, _ajaxError, _getUrl, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatChannelArchiveStatus;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelArchiveStatus = _exports.default = (_class = (_ChatChannelArchiveStatus = class ChatChannelArchiveStatus extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    }
    get shouldRender() {
      return this.currentUser.admin && (0, _utils.isPresent)(this.args.channel.archive);
    }
    get channelArchiveFailedMessage() {
      const archive1 = this.args.channel.archive;
      const translationKey1 = !archive1.topicId ? "chat.channel_status.archive_failed_no_topic" : "chat.channel_status.archive_failed";
      return (0, _template2.htmlSafe)(_discourseI18n.default.t(translationKey1, {
        completed: archive1.messages,
        total: archive1.totalMessages,
        topic_url: this.topicUrl
      }));
    }
    get channelArchiveCompletedMessage() {
      return (0, _template2.htmlSafe)(_discourseI18n.default.t("chat.channel_status.archive_completed", {
        topic_url: this.topicUrl
      }));
    }
    retryArchive() {
      return this.chatApi.createChannelArchive(this.args.channel.id).catch(_ajaxError.popupAjaxError);
    }
    get topicUrl() {
      if (!this.args.channel.archive.topicId) {
        return "";
      }
      return (0, _getUrl.default)(`/t/-/${this.args.channel.archive.topicId}`);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.shouldRender}}
        {{#if @channel.archive.failed}}
          <div
            class={{concatClass
              "alert alert-warn chat-channel-retry-archive"
              @channel.status
            }}
          >
            <div class="chat-channel-archive-failed-message">
              {{this.channelArchiveFailedMessage}}
            </div>
  
            <div class="chat-channel-archive-failed-retry">
              <DButton
                @action={{this.retryArchive}}
                @label="chat.channel_archive.retry"
              />
            </div>
          </div>
        {{else if @channel.archive.completed}}
          <div
            class={{concatClass "chat-channel-archive-status" @channel.status}}
          >
            {{this.channelArchiveCompletedMessage}}
          </div>
        {{/if}}
      {{/if}}
    
  */
  {
    "id": "WUzspRR4",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[41,[30,1,[\"archive\",\"failed\"]],[[[1,\"        \"],[10,0],[15,0,[28,[32,0],[\"alert alert-warn chat-channel-retry-archive\",[30,1,[\"status\"]]],null]],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-channel-archive-failed-message\"],[12],[1,\"\\n            \"],[1,[30,0,[\"channelArchiveFailedMessage\"]]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"chat-channel-archive-failed-retry\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@action\",\"@label\"],[[30,0,[\"retryArchive\"]],\"chat.channel_archive.retry\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"archive\",\"completed\"]],[[[1,\"        \"],[10,0],[15,0,[28,[32,0],[\"chat-channel-archive-status\",[30,1,[\"status\"]]],null]],[12],[1,\"\\n          \"],[1,[30,0,[\"channelArchiveCompletedMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-archive-status.js",
    "scope": () => [_concatClass.default, _dButton.default],
    "isStrictMode": true
  }), _ChatChannelArchiveStatus), _ChatChannelArchiveStatus), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "retryArchive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "retryArchive"), _class.prototype)), _class);
});