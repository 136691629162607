define("discourse/plugins/chat/discourse/components/chat/navbar/toggle-drawer-button", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatNavbarToggleDrawerButton;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatNavbarToggleDrawerButton = _exports.default = (_class = (_ChatNavbarToggleDrawerButton = class ChatNavbarToggleDrawerButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor2, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DButton
        @icon={{if
          this.chatStateManager.isDrawerExpanded
          "angle-double-down"
          "angle-double-up"
        }}
        @action={{this.chat.toggleDrawer}}
        @title={{if
          this.chatStateManager.isDrawerExpanded
          "chat.collapse"
          "chat.expand"
        }}
        class="btn-transparent no-text c-navbar__toggle-drawer-button"
      />
    
  */
  {
    "id": "PE+O5hOa",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-transparent no-text c-navbar__toggle-drawer-button\"]],[[\"@icon\",\"@action\",\"@title\"],[[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"angle-double-down\",\"angle-double-up\"],[30,0,[\"chat\",\"toggleDrawer\"]],[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"chat.collapse\",\"chat.expand\"]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/toggle-drawer-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ChatNavbarToggleDrawerButton), _ChatNavbarToggleDrawerButton), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});