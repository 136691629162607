define("discourse/plugins/chat/discourse/controllers/preferences-chat", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/services/chat-audio-manager"], function (_exports, _controller, _object, _service, _ajaxError, _environment, _decorators, _discourseI18n, _chatAudioManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const CHAT_ATTRS = ["chat_enabled", "only_chat_push_notifications", "ignore_channel_wide_mention", "show_thread_title_prompts", "chat_sound", "chat_email_frequency", "chat_header_indicator_preference", "chat_separate_sidebar_mode"];
  const HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = "never";
  const HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = "dm_and_mentions";
  const HEADER_INDICATOR_PREFERENCE_ALL_NEW = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = "all_new";
  const HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = "only_mentions";
  let PreferencesChatController = _exports.default = (_class = class PreferencesChatController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatAudioManager", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _defineProperty(this, "subpageTitle", _discourseI18n.default.t("chat.admin.title"));
      _defineProperty(this, "emailFrequencyOptions", [{
        name: _discourseI18n.default.t("chat.email_frequency.never"),
        value: "never"
      }, {
        name: _discourseI18n.default.t("chat.email_frequency.when_away"),
        value: "when_away"
      }]);
      _defineProperty(this, "headerIndicatorOptions", [{
        name: _discourseI18n.default.t("chat.header_indicator_preference.all_new"),
        value: HEADER_INDICATOR_PREFERENCE_ALL_NEW
      }, {
        name: _discourseI18n.default.t("chat.header_indicator_preference.dm_and_mentions"),
        value: HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS
      }, {
        name: _discourseI18n.default.t("chat.header_indicator_preference.only_mentions"),
        value: HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS
      }, {
        name: _discourseI18n.default.t("chat.header_indicator_preference.never"),
        value: HEADER_INDICATOR_PREFERENCE_NEVER
      }]);
      _defineProperty(this, "chatSeparateSidebarModeOptions", [{
        name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.always"),
        value: "always"
      }, {
        name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.fullscreen"),
        value: "fullscreen"
      }, {
        name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.never"),
        value: "never"
      }]);
    }
    get chatSeparateSidebarMode() {
      const mode = this.model.get("user_option.chat_separate_sidebar_mode");
      if (mode === "default") {
        return this.siteSettings.chat_separate_sidebar_mode;
      } else {
        return mode;
      }
    }
    chatSounds() {
      return Object.keys(_chatAudioManager.CHAT_SOUNDS).map(value => {
        return {
          name: _discourseI18n.default.t(`chat.sounds.${value}`),
          value
        };
      });
    }
    onChangeChatSound(sound) {
      if (sound) {
        this.chatAudioManager.play(sound);
      }
      this.model.set("user_option.chat_sound", sound);
    }
    save() {
      this.set("saved", false);
      return this.model.save(CHAT_ATTRS).then(() => {
        this.set("saved", true);
        if (!(0, _environment.isTesting)()) {
          location.reload();
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatAudioManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chatSounds", [_decorators.default], Object.getOwnPropertyDescriptor(_class.prototype, "chatSounds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeChatSound", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeChatSound"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
});