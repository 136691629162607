define("discourse/plugins/chat/discourse/components/chat/modal/delete-messages-confirm", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dModal, _dModalCancel, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _DeleteMessagesConfirm;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let DeleteMessagesConfirm = _exports.default = (_class = (_DeleteMessagesConfirm = class DeleteMessagesConfirm extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
    }
    async delete() {
      try {
        await this.chatApi.trashMessages(this.args.model.sourceChannel.id, this.args.model.selectedMessageIds);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.args.closeModal();
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal @closeModal={{@closeModal}} @headerClass="hidden">
        <:body>
          {{i18n
            "chat.delete_messages.confirm"
            count=@model.selectedMessageIds.length
          }}
        </:body>
        <:footer>
          <DButton
            class="btn-primary"
            @action={{this.delete}}
            @label="delete"
            @icon="trash-alt"
          />
          <DModalCancel @close={{@closeModal}} />
        </:footer>
      </DModal>
    
  */
  {
    "id": "omRHNVk9",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@closeModal\",\"@headerClass\"],[[30,1],\"hidden\"]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"chat.delete_messages.confirm\"],[[\"count\"],[[30,2,[\"selectedMessageIds\",\"length\"]]]]]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"delete\"]],\"delete\",\"trash-alt\"]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/delete-messages-confirm.js",
    "scope": () => [_dModal.default, _i18n.default, _dButton.default, _dModalCancel.default],
    "isStrictMode": true
  }), _DeleteMessagesConfirm), _DeleteMessagesConfirm), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class);
});