define("discourse/plugins/chat/discourse/services/chat-notification-manager", ["exports", "@ember/service", "discourse/lib/desktop-notifications", "discourse/lib/plugin-api", "discourse-common/config/environment", "discourse-common/utils/decorators"], function (_exports, _service, _desktopNotifications, _pluginApi, _environment, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatNotificationManager = _exports.default = (_class = class ChatNotificationManager extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "presence", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "appEvents", _descriptor5, this);
      _defineProperty(this, "_subscribedToCore", true);
      _defineProperty(this, "_subscribedToChat", false);
      _defineProperty(this, "_countChatInDocTitle", true);
    }
    start() {
      if (!this._shouldRun()) {
        return;
      }
      this.set("_chatPresenceChannel", this.presence.getChannel(`/chat-user/chat/${this.currentUser.id}`));
      this.set("_corePresenceChannel", this.presence.getChannel(`/chat-user/core/${this.currentUser.id}`));
      this._chatPresenceChannel.subscribe();
      this._corePresenceChannel.subscribe();
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.onPageChange(this._pageChanged);
      });
      this._pageChanged();
      this._chatPresenceChannel.on("change", this._subscribeToCorrectNotifications);
      this._corePresenceChannel.on("change", this._subscribeToCorrectNotifications);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (!this._shouldRun()) {
        return;
      }
      this._chatPresenceChannel.off("change", this._subscribeToCorrectNotifications);
      this._chatPresenceChannel.unsubscribe();
      this._chatPresenceChannel.leave();
      this._corePresenceChannel.off("change", this._subscribeToCorrectNotifications);
      this._corePresenceChannel.unsubscribe();
      this._corePresenceChannel.leave();
    }
    shouldCountChatInDocTitle() {
      return this._countChatInDocTitle;
    }
    _pageChanged() {
      if (this.chatStateManager.isActive) {
        this._chatPresenceChannel.enter({
          onlyWhileActive: false
        });
        this._corePresenceChannel.leave();
      } else {
        this._chatPresenceChannel.leave();
        this._corePresenceChannel.enter({
          onlyWhileActive: false
        });
      }
    }
    _coreAlertChannel() {
      return (0, _desktopNotifications.alertChannel)(this.currentUser);
    }
    _chatAlertChannel() {
      return `/chat${(0, _desktopNotifications.alertChannel)(this.currentUser)}`;
    }
    _subscribeToCorrectNotifications() {
      const oneTabForEachOpen = this._chatPresenceChannel.count > 0 && this._corePresenceChannel.count > 0;
      if (oneTabForEachOpen) {
        this.chatStateManager.isActive ? this._subscribeToChat({
          only: true
        }) : this._subscribeToCore({
          only: true
        });
      } else {
        this._subscribeToBoth();
      }
    }
    _subscribeToBoth() {
      this._subscribeToChat();
      this._subscribeToCore();
    }
    _subscribeToChat() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        only: false
      };
      this.set("_countChatInDocTitle", true);
      if (!this._subscribedToChat) {
        this.messageBus.subscribe(this._chatAlertChannel(), this.onMessage);
        this.set("_subscribedToChat", true);
      }
      if (opts.only && this._subscribedToCore) {
        this.messageBus.unsubscribe(this._coreAlertChannel(), this.onMessage);
        this.set("_subscribedToCore", false);
      }
    }
    _subscribeToCore() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        only: false
      };
      if (opts.only) {
        this.set("_countChatInDocTitle", false);
      }
      if (!this._subscribedToCore) {
        this.messageBus.subscribe(this._coreAlertChannel(), this.onMessage);
        this.set("_subscribedToCore", true);
      }
      if (opts.only && this._subscribedToChat) {
        this.messageBus.unsubscribe(this._chatAlertChannel(), this.onMessage);
        this.set("_subscribedToChat", false);
      }
    }
    onMessage(data) {
      if (data.channel_id === this.chat.activeChannel?.id) {
        return;
      }
      return (0, _desktopNotifications.onNotification)(data, this.siteSettings, this.currentUser, this.appEvents);
    }
    _shouldRun() {
      return this.chat.userCanChat && !(0, _environment.isTesting)();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_pageChanged", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_pageChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_subscribeToCorrectNotifications", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_subscribeToCorrectNotifications"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onMessage"), _class.prototype)), _class);
});