define("discourse/plugins/chat/discourse/components/chat/routes/channel", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-side-panel", "discourse/plugins/chat/discourse/components/full-page-chat", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _navbar, _chatSidePanel, _fullPageChat, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatRoutesChannel;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatRoutesChannel = _exports.default = (_class = (_ChatRoutesChannel = class ChatRoutesChannel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get getChannelsRoute() {
      return this.args.channel.isDirectMessageChannel ? "chat.direct-messages" : "chat.channels";
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channel">
        <Navbar as |navbar|>
          {{#if this.site.mobileView}}
            <navbar.BackButton @route={{this.getChannelsRoute}} />
          {{/if}}
          <navbar.ChannelTitle @channel={{@channel}} />
          <navbar.Actions as |action|>
            <action.OpenDrawerButton />
            <action.ThreadsListButton @channel={{@channel}} />
          </navbar.Actions>
        </Navbar>
  
        <FullPageChat
          @channel={{@channel}}
          @targetMessageId={{@targetMessageId}}
        />
      </div>
  
      <SidePanel>
        {{outlet}}
      </SidePanel>
    
  */
  {
    "id": "1ezSWffb",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channel\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\"],[[30,0,[\"getChannelsRoute\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,3,[\"OpenDrawerButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,3,[\"ThreadsListButton\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,1],null,[[\"@channel\",\"@targetMessageId\"],[[30,2],[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n      \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"navbar\",\"@channel\",\"action\",\"@targetMessageId\"],false,[\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel.js",
    "scope": () => [_navbar.default, _fullPageChat.default, _chatSidePanel.default],
    "isStrictMode": true
  }), _ChatRoutesChannel), _ChatRoutesChannel), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});